'use strict'

const React = require('react')
const baseImage = require('./images/login-wombat-base.png')
const faceBaseImage = require('./images/login-wombat-face-base.png')
const faceBlinkImage = require('./images/login-wombat-face-blink.png')
const faceHappyImage = require('./images/login-wombat-face-happy.png')
const styles = require('./styles/wombat.css')
const globalStyles = require('../../../styles/global.css')

class Wombat extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isGiggling: false,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this)
  }

  handleClick() {
    this.setState({isGiggling: true})
  }

  handleAnimationEnd() {
    this.setState({isGiggling: false})
  }

  render() {
    const faceMarkup = (
      <div className={styles.animationFaceBounce}>
        <div className={styles.wombatFace}>
          <img src={faceBaseImage} alt="" role="presentation" className={styles.wombatFaceBase} />
          <img src={faceBlinkImage} alt="" role="presentation" className={styles.wombatFaceBlink} />
          <img src={faceHappyImage} alt="" role="presentation" className={styles.wombatFaceHappy} />
        </div>
      </div>
    )

    const wombatWrapperStyles = `${styles.animationSway} ${styles.wombatWrapper}  ${globalStyles.clean}`
    const {isGiggling} = this.state

    return (
      <React.Fragment>
        <div className={styles.wombatContainer}>
          <button
            className={wombatWrapperStyles}
            onClick={this.handleClick}
            aria-label="Make the animated wombat visibly giggle"
          >
            <div className={styles.animationBounce}>
              <div
                className={[`${styles.wombat} ${globalStyles.clean}`, isGiggling ? styles.animationGiggle : null].join(
                  ' ',
                )}
                onAnimationEnd={this.handleAnimationEnd}
              >
                {faceMarkup}
                <img alt="" role="presentation" src={baseImage} />
              </div>
            </div>
          </button>
        </div>
        <div className={styles.wombatFocusBox} />
      </React.Fragment>
    )
  }
}

module.exports = Wombat
