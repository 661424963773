'use strict'

const React = require('react')
const npmLogoImage = require('./images/login-npm-logo.png')
const PropTypes = require('prop-types')
const styles = require('./styles/shade.css')
const globalStyle = require('../../../styles/global.css')

const Shade = props => {
  const {onClick, isDown} = props

  const shadeStyles = [styles.shade, globalStyle.clean, isDown ? styles.isDown : null].join(' ')
  const shadeHandleFocusBoxStyles = [styles.shadeHandleFocusBox, isDown ? styles.isDown : null].join(' ')

  return (
    <React.Fragment>
      <div className={styles.shadeContainer}>
        <button
          className={shadeStyles}
          aria-label="Toggle between showing and hiding a shade over a wombat's head"
          onClick={onClick}
        >
          <div className={styles.shadeLogo}>
            <img alt="" role="presentation" src={npmLogoImage} />
          </div>
          <div className={styles.shadeDetail} />
          <div className={styles.shadeHandle} />
        </button>
      </div>
      <div className={shadeHandleFocusBoxStyles} />
    </React.Fragment>
  )
}

Shade.propTypes = {
  /**
   * Stylizes the shade UI to cover the Wombat's eyes. No peaking!
   */
  isDown: PropTypes.bool,
}
Shade.defaultProps = {
  isDown: true,
}

module.exports = Shade
