'use strict'

const React = require('react')
const PropTypes = require('prop-types')
const GenericForm = require('../forms/generic')
const forms = require('../../styles/forms.css')
const Link = require('@npm/spiferack/link')
const GenericInput = require('../inputs/generic')
const PasswordInput = require('../inputs/password')
const Wombat = require('./wombat/index')

class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isTypingPassword: false,
    }
    this.handleOnPasswordBlur = this.handleOnPasswordBlur.bind(this)
    this.handleOnPasswordFocus = this.handleOnPasswordFocus.bind(this)
  }

  handleOnPasswordBlur() {
    this.setState({isTypingPassword: false})
  }

  handleOnPasswordFocus() {
    this.setState({isTypingPassword: true})
  }

  render() {
    const {action, formData = {}, next} = this.props
    return [
      <Wombat key="fun-wombat" isTypingPassword={this.state.isTypingPassword} />,
      <GenericForm
        key="form"
        action={action}
        formId="login"
        formData={formData}
        onSubmit={() => {}} // noop to get default form submit
        buttonText="Sign In"
        className="ma0"
      >
        <h1 className={forms.liminalHeading}>Sign In</h1>
        <GenericInput name="username" label="Username" formData={formData} className="mb2" />
        <div className="dib fr">
          <Link>
            <a href="/forgot">Forgot password?</a>
          </Link>
        </div>
        <PasswordInput
          formData={formData}
          minLength="0"
          name="password"
          showToggleButton={true}
          onBlur={this.handleOnPasswordBlur}
          onFocus={this.handleOnPasswordFocus}
        />
      </GenericForm>,
      <Link key="link">
        <a href={`/signup${next}`} className={`${forms.link}`} rel="npm:signup">
          Create Account
        </a>
      </Link>,
    ]
  }
}

LoginForm.propTypes = {
  action: PropTypes.string,
}

module.exports = LoginForm
