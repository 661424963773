'use strict'

const React = require('react')
const PropTypes = require('prop-types')
const Shade = require('./shade')
const Wombat = require('./wombat')
const styles = require('./styles/canvas.css')

class Canvas extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShadeDown: false,
    }
    this.handleOnShadeClick = this.handleOnShadeClick.bind(this)
  }

  /**
   * Auto-toggles the shade based on the Password input field's
   * blur/focus interactions.
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.isTypingPassword !== this.props.isTypingPassword) {
      this.setState({isShadeDown: nextProps.isTypingPassword})
    }
  }

  /**
   * Allows for the user to interact with the shade.
   */
  handleOnShadeClick() {
    this.setState({isShadeDown: !this.state.isShadeDown})
  }

  render() {
    const {isShadeDown: isDown} = this.state

    const canvasStyles = [styles.canvas, isDown ? styles.isShadeDown : null].join(' ')

    return (
      <div className={canvasStyles}>
        <Shade onClick={this.handleOnShadeClick} isDown={isDown} />
        <Wombat />
      </div>
    )
  }
}

Canvas.propTypes = {
  /**
   * Determines if the shade goes down, and if you're able to interact with
   * the shade.
   */
  isTypingPassword: PropTypes.bool,
}
Canvas.defaultProps = {
  isTypingPassword: false,
}

module.exports = Canvas
